import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  ControlRow,
  FlexBody,
  FlexHeader,
  MainCard,
  ThreeColField,
  TitleLarge,
  TitleMedium,
  TwoColField,
} from "../helpers/BaseLayoutStyles";
import Loading from "../helpers/Loading";
import { Select } from "baseui/select";
import data from "../data/results.json";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const [loading, setLoading] = useState(true);
  const [inited, setInited] = useState(false);
  const [filters, setFilters] = useState(null as any);
  const [year, setYear] = useState(null as any);
  const [eventType, setEventType] = useState(null as any);
  const [sortedData, setSortedData] = useState([] as any);
  const [filteredData, setFilteredData] = useState([] as any);

  let navigate = useNavigate();

  const applyFilters = (y: any, et: any) => {
    console.log(y);
    console.log(et);

    let tempFilteredData: any[] = [];
    data.forEach((item: any) => {
      let yearMatch =
        y[0].id == "-- All Years --" || item.date.includes(y[0].id);
      let eventMatch =
        et[0].id == "-- All Event Types --" || item.eventType == et[0].id;

      if (yearMatch && eventMatch) {
        tempFilteredData.push(item);
      }
    });

    setFilteredData(tempFilteredData);
  };

  const initPage = async () => {
    if (!inited) {
      setLoading(true);

      // Populate Sorted Data and Filters.
      if (sortedData.length == 0) {
        let tempSortedData: any[] = [];
        let tempYears: any[] = [];
        let tempEventTypes: any[] = [];
        let yearDups: any[] = [];
        let eventTypeDups: any[] = [];

        // Add default filters
        tempYears.push({ id: "-- All Years --", label: "-- All Years --" });
        tempEventTypes.push({
          id: "-- All Event Types --",
          label: "-- All Event Types --",
        });
        tempYears.push({
          id: "Unknown Date",
          label: "Unknown Date",
        });

        data.forEach((item: any) => {
          // Take the data from data (the JSON file), add a numerical date value and sort it so that we can have everything in descending date order
          let i = {
            ...item,
            dateValue:
              item.date == "" || item.date.toLowerCase().startsWith("unknown")
                ? -1
                : new Date(item.date).getTime(),
          };
          tempSortedData.push(i);

          // Add the event type if not already in the list
          if (!eventTypeDups.includes(item.eventType)) {
            tempEventTypes.push({ id: item.eventType, label: item.eventType });
            eventTypeDups.push(item.eventType);
          }

          // Add the event year if not already in the list
          if (!item.date.toLowerCase().includes("unknown")) {
            let yyyy = new Date(item.date).getFullYear().toString();
            if (!yearDups.includes(yyyy)) {
              tempYears.push({ id: yyyy, label: yyyy });
              yearDups.push(yyyy);
            }
          }
        });

        // Sort our datasets
        tempSortedData = tempSortedData.sort((a: any, b: any) => {
          return a.dateValue < b.dateValue ? 1 : -1;
        });
        tempYears = tempYears.sort((a: any, b: any) => {
          if (a.id == "-- All Years --" || b.id == "-- All Years --") return 2;
          if (a.id == "Unknown Date" || b.id == "-Unknown Date") return -2;
          return a.id < b.id ? 1 : -1;
        });
        tempEventTypes = tempEventTypes.sort((a: any, b: any) => {
          return a.id < b.id ? 1 : -1;
        });

        setSortedData(tempSortedData);
        setFilteredData(tempSortedData);

        data.forEach((item: any) => {});

        // Sort the event types alphabetically
        tempEventTypes.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

        setYear([tempYears[0]]);
        setEventType([tempEventTypes[0]]);

        console.log(tempYears);
        setFilters({ years: tempYears, eventTypes: tempEventTypes });
      }

      setInited(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    initPage();
  }, [inited]);

  return (
    <MainCard>
      <FlexHeader>
        <TitleMedium>Results Archive</TitleMedium>
      </FlexHeader>

      <FlexBody>
        {loading == true && <Loading key="loading"></Loading>}

        {loading == false && filters && (
          <>
            <div style={{ marginBottom: "1rem" }}>
              This archive contains a non-complete set of results from events
              run by HCMC, or in which HCMC competitors may have competed.
              <br />
              New data will be added to this archive as it becomes available.
              <br />
            </div>
            <TwoColField>
              <div style={{ marginBottom: "1rem" }}>
                <Select
                  value={year}
                  clearable={false}
                  onChange={(params) => {
                    setYear(params.value);
                    applyFilters(params.value, eventType);
                  }}
                  options={filters.years}
                  placeholder=""
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Select
                  value={eventType}
                  clearable={false}
                  onChange={(params) => {
                    setEventType(params.value);
                    applyFilters(year, params.value);
                  }}
                  options={filters.eventTypes}
                  placeholder=""
                />
              </div>
            </TwoColField>
          </>
        )}

        {loading == false && (
          <>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Event Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item: any) => {
                  let url = "/assets/results/" + item.link;

                  return (
                    <tr key={item.link}>
                      <td>{item.date}</td>
                      <td>{item.eventType}</td>
                      <td>
                        <a href={url} target="_blank">
                          {item.description}
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </FlexBody>
    </MainCard>
  );
};
export default Page;
