import React from "react";
import styled from "styled-components";
import Main from "./layout/Main";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const engine = new Styletron();

function App() {
	return (
		<>
				<StyletronProvider value={engine}>
					<BaseProvider theme={LightTheme}>
						<BrowserRouter> 
							<Main></Main>
							<ToastContainer />
						</BrowserRouter>
					</BaseProvider>
				</StyletronProvider>
		</>
	);
}

export default App;
