/*
	Main.tsx
	Base SPA page for our React app. This controls:
	* User Authentication - whether to display a Login screen or Dashboard with User details
	* Dashboard layout / Nav menu 
	* Routes to individual pages within the SPA site
*/

import React, { useContext, useEffect, useState } from "react";
import {
  ContainerBase,
  ControlRow,
  FlexFooter,
  LayoutPanel,
  MainPanel,
} from "../helpers/BaseLayoutStyles";
//import styled from "styled-components";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import styled from "styled-components";
import Unavailable from "./Unavailable";
import Results from "./Results";

const Main = () => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // let navigate = useNavigate();

  return (
    <LayoutPanel>
      <MainPanel>
        {isError ? (
          <Unavailable />
        ) : (
          <>
            <Routes>
              <Route path="/results/*" element={<Results />} />
              <Route path="/" element={<Results />} />
            </Routes>
          </>
        )}
      </MainPanel>
    </LayoutPanel>
  );
};

export default Main;
