import { Button } from "baseui/button";
import React, { useState, useContext } from "react";
import styled from "styled-components";

const Container = styled.div`
	display: grid;
	grid-template-rows: auto;
	justify-items: center;
	> img {
		width: 30%;
	}

	&.large {
		> img {
			width: 60%;
		}
	}
`;

const SignOutArea = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;
`;

const Unavailable = () => {
	return (
		<div>
			<Container>
				<h2>This application is currently unavailable</h2>
				<p>Check back soon</p>
			</Container>
			
		</div>
	);
};
export default Unavailable;
