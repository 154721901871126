import styled from "styled-components";

// Styled Objects

const Footer = styled.div`
  display: flex;
  height: 35px;
  padding-left: 1rem;
  padding-right: 1rem;
`;
const FooterBox = styled.div`
  display: block;
  overflow-y: none;
  width: 100%;
  padding-top: 5px;
`;

const LayoutPanel = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  flex-flow: column;
  iframe {
    border: none !important;
  }

  .content-shadow {
    box-shadow: 0 10px 50px -2px rgba(0, 0, 0, 0.14);
  }
`;

const ContainerBase = styled.div``;

const ContainerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const TwoColField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

const ThreeColField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
`;

const TitleLarge = styled.h1`
  margin-top: 0px;
`;

const TitleMedium = styled.h2`
  margin-top: 0px;
`;

const MainPanel = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const ContainerBox = styled.div`
  margin: 0.25rem;
  margin-top: 0;
  box-shadow: 0 10px 50px -2px rgba(0, 0, 0, 0.14);
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-y: auto;
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  flex: 1 1 auto;
`;

const FlexHeader = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: auto auto;
  div {
    text-align: right;
  }
`;

const FlexBody = styled.div`
  flex: 1 1 auto;
  flex-grow: 1;
  display: content;
`;

const FlexFooter = styled.div`
  display: content;
  bottom: 0px;
  height: 30px;
`;

const StyledTableRow = styled.div`
  display: contents;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

const FieldText = styled.div`
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
`;

const NavigationLink = styled.a`
  cursor: pointer;
`;

// Composite Objects
const MainCard = (props: any) => {
  return <ContainerBox>{props.children}</ContainerBox>;
};

const ControlRow = (props: any) => {
  return <Row>{props.children}</Row>;
};

const ActionRow = (props: any) => {
  return <FlexHeader>{props.children}</FlexHeader>;
};

export {
  ContainerBase,
  MainCard,
  ContainerPage,
  LayoutPanel,
  FlexHeader,
  FlexBody,
  FlexFooter,
  StyledTableRow,
  ActionRow,
  ControlRow,
  TwoColField,
  ThreeColField,
  Row,
  MainPanel,
  Footer,
  FooterBox,
  TitleLarge,
  TitleMedium,
  FieldText,
  NavigationLink,
};
